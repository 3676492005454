import { useMemo } from "react";
import { getTranslations } from "../helpers/translations";
import { useIntl } from "./useIntl";
import { useSettingsStaticQuery } from "./staticQueries/useSettingsStaticQuery";

export const useRecipesMainTab = (): string => {
  const intl = useIntl();
  const data = useSettingsStaticQuery();

  const recipeMainTab = useMemo((): string => {
    const translatedData =
      getTranslations(data, "directus.settings.translations", intl.locale) ??
      {};

    return translatedData?.recipes_main_tab;
  }, [data, intl]);

  return recipeMainTab;
};
