import { graphql } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { getAssetURL } from "../helpers/assets";
import { getTranslations } from "../helpers/translations";
import { getAuthor, mapRecipeCard } from "../helpers/recipe";
import Page from "../components/Page";
import RecipeDetailMain, {
  RecipeDetailMainProps,
} from "../components/RecipeDetailMain";
import RecipeDetails, { RecipeDetailsProps } from "../components/RecipeDetails";
import { ThisRecipeFeaturesProps } from "../components/ThisRecipeFeatures";
import {
  RecipesSection,
  RecipesSectionProps,
} from "../components/RecipeLanding";
import { ROUTES } from "../helpers/routes";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { OG_TYPES } from "../components/SEO/SEO";
import { isPublished } from "../helpers/directus";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { jsPDF } from "jspdf";
import PreviewPrint from "../components/PreviewPrint";
import { StringParam, useQueryParam } from "use-query-params";
const RecipeDetailPage = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();
  const [q] = useQueryParam("q", StringParam);
  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.recipe_page_by_id.translations",
      intl.locale
    );
  }, [data]);
  const getRecipeDetailMainData = useCallback((): RecipeDetailMainProps => {
    const translatedData = getRootTranslatedData();
    const translatedRecipe = getTranslations(
      translatedData,
      "recipe.translations",
      intl.locale
    );
    return {
      ...translatedRecipe,
      isPublished: isPublished(translatedData?.recipe_content_status),
      image: {
        src: getAssetURL(translatedRecipe?.image),
        alt: translatedRecipe?.image_alt,
      },
      instructions: [
        {
          text: labels?.servesLabel,
          quantity: translatedRecipe?.serves_value,
        },
        {
          text: labels?.prepTimeLabel,
          quantity: translatedRecipe?.prep_time_value,
        },
        {
          text: labels?.cookTimeLabel,
          quantity: translatedRecipe?.cook_time_value,
        },
      ],
      tags: getTranslations(
        translatedRecipe?.tags,
        "recipe_tag_id.recipe_tag_translations",
        intl.locale
      ),
      back: {
        label: labels?.backToAllRecipesLabel,
        link: q ? `${ROUTES.RECIPES_SEARCH}?q=${q}` : ROUTES.RECIPES,
      },
      schemaTitle: translatedRecipe?.schema_title,
      file: getAssetURL(translatedRecipe?.recipe_file),
      author: getAuthor(translatedRecipe?.author, labels),
    };
  }, [getRootTranslatedData, intl]);
  const getRecipeTabsData = useCallback((): RecipeDetailsProps => {
    const translatedData = getRootTranslatedData();
    const translatedRecipe = getTranslations(
      translatedData,
      "recipe.translations",
      intl.locale
    );
    return {
      isPublished: isPublished(translatedData?.recipe_content_status),
      leftTabLabel: translatedRecipe?.left_tab_title,
      rightTabLabel: translatedRecipe?.right_tab_title,
      leftTabContent: translatedRecipe?.left_tab_content,
      rightTabContent: {
        preparationList: translatedRecipe?.right_tab_content,
        url: translatedRecipe?.video_url,
      },
    };
  }, [getRootTranslatedData, intl]);
  const getThisRecipeFeaturesData = useCallback((): ThisRecipeFeaturesProps => {
    const translatedData = getRootTranslatedData();
    const selectedVariantSlug = translatedData?.recipe?.product_variant?.variant_slug;
    const { product = [] } =
      getTranslations(
        translatedData,
        "recipe.product_page.translations",
        intl.locale
      ) || {};
    const { variants = [] } =
      getTranslations(product, "translations", intl.locale) || {};
    const productVariants = variants
      ?.map(({ product_variant_id }: { product_variant_id: any }) => {
        const variant =
          getTranslations(product_variant_id, "translations", intl.locale) ||
          {};
        const variant_slug = product_variant_id?.variant_slug;
        return { ...variant, variant_slug };
      })
      const selectedVariant = productVariants?.find(
        ({
          status,
          variant_slug,
        }: {
          status: string;
          variant_slug: string;
        }) => {
          return (
            isPublished(status) &&
            selectedVariantSlug === variant_slug
          );
        },
      );
      const defaultVariant =
        selectedVariant ??
        productVariants?.find(
          ({ status }: { status: string }) =>
            isPublished(status),
        );

    return {
      card: {
        image: {
          src: getAssetURL(defaultVariant?.small_image),
          alt: defaultVariant?.small_image_alt,
        },
        primaryBtn: {
          label: labels?.productDetails,
          link: `${ROUTES.PRODUCT_DETAIL}/${
            translatedData?.recipe?.product_page?.product_page_slug
          }${
            selectedVariant?.variant_slug ? `/${selectedVariant.variant_slug}` : ""
          }`,
        },
        secondaryBtn: {
          label: labels?.findNearMe,
          link: ROUTES.WHERE_TO_FIND,
        },
        title: labels?.thisRecipeFeatures,
        subtitle: defaultVariant?.title,
        description: defaultVariant?.description,
      },
    };
  }, [getRootTranslatedData, intl]);
  const getFeaturedRecipesSection = (): RecipesSectionProps => {
    const translatedData = getRootTranslatedData();
    return {
      title: labels?.youMayAlsoLike,
      recipeList: isPublished(translatedData?.you_may_also_like_status)
        ? translatedData?.you_may_also_likes_recipes
            ?.map(({ recipe_page_id }: { recipe_page_id: any }) =>
              mapRecipeCard(recipe_page_id, labels, intl.locale)
            )
            ?.filter(({ status }: { status: string }) => isPublished(status))
        : [],
    };
  };
  const recipeMainData = getRecipeDetailMainData();
  const recipeTabsData = getRecipeTabsData();
  const [isPrintContentVisible, setIsPrintContentVisible] = useState(false);
  const pdfContent = {
    title: recipeMainData?.title,
    image: recipeMainData?.image,
    author: recipeMainData?.author,
    instructions: recipeMainData?.instructions,
    ingredientsTitle: recipeTabsData?.leftTabLabel,
    ingredientsContent: recipeTabsData?.leftTabContent,
    preparationTitle: recipeTabsData?.rightTabLabel,
    preparationContent: recipeTabsData?.rightTabContent,
  };
  const createPDF = async () => {
    setIsPrintContentVisible(true);
    setTimeout(async () => {
      const report = new jsPDF("portrait", "pt", "a4");
      report.setFontSize(15);
      await report.html(document.querySelector("#report") as any, {
        filename: "BM - Recipe",
        autoPaging: "text",
        html2canvas: {
          useCORS: true,
          allowTaint: true,
        },
        margin: [32, 50, 50, 50],
        image: {
          type: "jpeg" || "png" || "webp",
          quality: 10,
        },
      });
      report.autoPrint();
      window.open(report.output("bloburl"));
    }, 1000);
    setTimeout(() => {
      setIsPrintContentVisible(false);
    }, 3000);
  };

  const [updatedPreparation, setUpdatedPreparation] = useState<string>("");

  const replacePTagsWithItemProp = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const pTags = doc.querySelectorAll("p");

    pTags.forEach((pTag) => {
      pTag.setAttribute("itemProp", "recipeInstructions");
    });

    setUpdatedPreparation(doc.body.innerHTML);
  };

  useEffect(() => {
    replacePTagsWithItemProp(recipeTabsData?.rightTabContent?.preparationList);
  }, [recipeTabsData?.rightTabContent?.preparationList]);

  return (
    <Page
      headerBackgroundColor={"rgb(71, 29, 14)"}
      helmet={
        <SEO
          {...parseSEO(getRootTranslatedData(), intl?.locale, {
            og_image: recipeMainData?.image?.src,
            og_type: OG_TYPES.article,
          })}
        />
      }
    >
      <div itemScope itemType="https://schema.org/Recipe">
        <RecipeDetailMain
          {...recipeMainData}
          printFunction={createPDF}
          loading={isPrintContentVisible}
        />
        <RecipeDetails
          {...{ ...getRecipeTabsData(), ...getThisRecipeFeaturesData() }}
        />
        <SchemaPreparation
          dangerouslySetInnerHTML={{ __html: updatedPreparation }}
        />
        <RecipesSection
          {...getFeaturedRecipesSection()}
          {...{
            headerColor: "#dd9271",
            bgColor: "#7a3818",
            itemBgColor: "#4d2715",
            itemTextColor: "#ffffff",
            itemLabelColor: "#dd9271",
          }}
        />
        {isPrintContentVisible && (
          <div id="report">
            <PreviewPrint {...pdfContent} />
          </div>
        )}
      </div>
    </Page>
  );
};

const SchemaPreparation = styled.p`
  display: none;
`;

export default RecipeDetailPage;
export const query = graphql`
  query ($id: ID!, $locale: String) {
    directus {
      recipe_page_by_id(id: $id) {
        recipe_page_slug
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            code
            name
          }
          status
          recipe_content_status
          recipe {
            product_variant {
              variant_slug
            }
            product_page {
              product_page_slug
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                variants_status
                product {
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    product_name
                    variants {
                      product_variant_id {
                        variant_slug
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          status
                          title
                          small_image_alt
                          small_image {
                            id
                            filename_disk
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              status
              title
              schema_title
              author
              serves_value
              prep_time_value
              cook_time_value
              tags {
                recipe_tag_id {
                  name
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    label
                  }
                }
              }
              image {
                id
                filename_disk
              }
              image_alt
              left_tab_title
              left_tab_content
              right_tab_title
              right_tab_content
              video_url
            }
          }
          you_may_also_like_status
          you_may_also_likes_recipes {
            recipe_page_id {
              recipe_page_slug
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                status
                recipe_content_status
                recipe {
                  product_page {
                    product_page_slug
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        code
                        name
                      }
                      variants_status
                      product {
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          product_name
                        }
                      }
                    }
                  }
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    title
                    schema_title
                    serves_value
                    prep_time_value
                    cook_time_value
                    author
                    image_alt
                    image {
                      id
                      filename_disk
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
