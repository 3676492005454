import { useMediaQuery } from "@react-hook/media-query";
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  toPixelsFromVW,
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA } from "../../helpers/theme";
import ThisRecipeFeatures, {
  ThisRecipeFeaturesProps,
} from "../ThisRecipeFeatures";
import { useIntl } from "../../hooks/useIntl";

export type RecipeIngredientsProps = {
  leftTabContent: string;
  card?: ThisRecipeFeaturesProps;
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

const RecipeIngredients: FC<RecipeIngredientsProps> = ({
  card,
  leftTabContent,
  containerRef,
}: RecipeIngredientsProps) => {
  const recipeCardRef = useRef<HTMLElement>();

  const desktopMatches = useRef(false);
  const mediaMatches = useMediaQuery(MEDIA.DESKTOP);
  desktopMatches.current = mediaMatches;
  const [updatedIngredients, setUpdatedIngredients] = useState<string>("");
  const intl = useIntl();

  const onScroll = useRef(() => {
    const container = containerRef.current;
    const div = recipeCardRef.current;
    const nav = document.getElementById("theNavBar");

    if (!(nav && container && div && desktopMatches.current)) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const containerRect = container.getBoundingClientRect();
    const containerTop = containerRect.top + scrollTop;
    const containerHeight = containerRect.height;
    const divRect = div.getBoundingClientRect();
    const divHeight = divRect.height;
    const PADDING_TOP = toPixelsFromVW(toVWDesktop(94));
    const PADDING_BOTTOM = toPixelsFromVW(toVWDesktop(80));

    const top = window.scrollY + nav.offsetHeight + PADDING_TOP;

    if (top < containerTop + PADDING_TOP) {
      div.style.position = "absolute";
      div.style.top = `${PADDING_TOP}px`;
    }
    if (top >= containerTop + PADDING_TOP) {
      div.style.position = "fixed";
      div.style.top = `${nav.offsetHeight + PADDING_TOP}px`;
    }
    if (top >= containerTop + containerHeight - divHeight - PADDING_BOTTOM) {
      div.style.position = "absolute";
      div.style.top = `${containerHeight - divHeight - PADDING_BOTTOM}px`;
    }
  }).current;

  useEffect(() => {
    if (desktopMatches.current) {
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, []);

  useEffect(() => {
    onScroll();
  }, [recipeCardRef.current]);

  const replacePTagsWithItemProp = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const pTags = doc.querySelectorAll("p");

    pTags.forEach((pTag) => {
      pTag.setAttribute("itemProp", "recipeIngredient");
    });

    setUpdatedIngredients(doc.body.innerHTML);
  };

  useEffect(() => {
    replacePTagsWithItemProp(leftTabContent);
  }, [leftTabContent]);

  return (
    <Container newPadding={intl.locale === "he-IL" ? true : false}>
      <IngredientDescription
        dangerouslySetInnerHTML={{
          __html: updatedIngredients,
        }}
      />
      <ThisRecipeFeatures ref={recipeCardRef} card={card} />
    </Container>
  );
};

const Container = styled.section<{ newPadding?: boolean }>`
  background-color: #033305;
  padding: 0 ${toVWMobile(25)}vw ${toVWMobile(20)}vw ${toVWMobile(16)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${(props) =>
      props.newPadding === true
        ? `0 ${toVWDesktop(180)}vw 0 0`
        : `0 ${toVWDesktop(50)}vw 0 0`};
    width: 100%;
    min-height: ${vwDesktop(500)};
  }
`;

const IngredientDescription = styled.p`
  border-top: 1px solid #2b6447;
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(28)}vw;
  }

  a {
    color: #75c154;
  }

  p {
    border-bottom: 1px solid #2b6447;
    padding: ${toVWMobile(20)}vw 0;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding: ${toVWDesktop(15)}vw 0;
    }
  }

  & .ingredients-quantity {
    color: #ffffff !important;
    font-family: ${(props) => props.theme.fontFamily},
      ${(props) => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${toREM(18)}rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: ${toVWMobile(28)}vw;
    margin-right: ${toVWMobile(20)}vw;
    width: ${toVWMobile(33)}vw;
    text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      line-height: ${toVWDesktop(28)}vw;
      margin-right: ${toVWDesktop(20)}vw;
      width: ${toVWDesktop(33)}vw;
    }
  }

  h3,
  b,
  strong {
    color: #ffffff;
    font-family: ${(props) => props.theme.fontFamily},
      ${(props) => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${toREM(24)}rem;
    font-weight: 900;
    font-style: normal;
    letter-spacing: ${toREM(1.44)}rem;
    line-height: normal;
    padding: ${toVWMobile(45)}vw 0 ${toVWMobile(20)}vw;
    text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
    text-transform: uppercase;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding: ${toVWDesktop(45)}vw 0 ${toVWDesktop(20)}vw;
    }
  }
`;

export default RecipeIngredients;
