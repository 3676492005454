import React from "react";
import styled from "styled-components";
import { toREM, toVWDesktop, toVWMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Image } from "../../types";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";

export type ThisRecipeFeaturesProps = {
  card?: {
    title: string;
    image: Image;
    subtitle: string;
    description: string;
    primaryBtn: { label: string; link: string };
    secondaryBtn: { label: string; link: string };
  };
};

// eslint-disable-next-line react/display-name
const ThisRecipeFeatures = React.forwardRef(
  ({ card }: ThisRecipeFeaturesProps, ref) => {
    return (
      <Container ref={ref}>
        <Card>
          <PictureWrapper>
            <Picture src={card?.image?.src} alt={card?.image?.alt} />
          </PictureWrapper>
          <TextWrapper>
            <CardSubtitle>{card?.title}</CardSubtitle>
            <CardTitle
              dangerouslySetInnerHTML={{
                __html: card?.subtitle,
              }}
            />
          </TextWrapper>

          <ButtonWrapper>
            <ButtonLink
              type={ButtonType.PRIMARY_LIGHT}
              label={card?.primaryBtn?.label}
              link={card?.primaryBtn?.link}
            />
            <ButtonLink
              type={ButtonType.SECONDARY_LIGHT}
              label={card?.secondaryBtn?.label}
              link={card?.secondaryBtn?.link}
            />
          </ButtonWrapper>
        </Card>
      </Container>
    );
  }
);

const Container = styled.div`
  margin-top: ${toVWMobile(100)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(425)}vw;
    margin-top: 0;
    padding-bottom: ${toVWDesktop(80)}vw;

    position: absolute;
    top: 0;
    right: ${toVWDesktop(100)}vw;
    z-index: 0;
  }
`;

const CardSubtitle = styled.p`
  color: #75c154;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(22)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: ${toREM(1.08)}rem;
  margin: 0 0 ${toVWMobile(10)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: 0 0 ${toVWDesktop(10)}vw;
  }
`;

const Card = styled.div`
  background-color: #012102;
  padding: 0 ${toVWMobile(24)}vw ${toVWMobile(24)}vw;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(25)}vw ${toVWDesktop(40)}vw;
  }
`;

const PictureWrapper = styled.div`
  height: ${toVWMobile(145)}vw;
  margin-bottom: ${toVWMobile(29)}vw;
  padding: 0 ${toVWMobile(25)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(150)}vw;
    margin-bottom: ${toVWDesktop(49)}vw;
    padding: 0 ${toVWDesktop(25)}vw;
  }
`;

const Picture = styled.img`
  height: ${toVWMobile(289)}vw;
  object-fit: contain;
  position: relative;
  top: ${toVWMobile(-100)}vw;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(250)}vw;
    top: ${toVWDesktop(-80)}vw;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(30)}vw;
  }
`;

const CardTitle = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.02em;
  line-height: ${toVWMobile(40)}vw;
  margin-bottom: ${toVWMobile(8)}vw;
  text-align: left;
  text-transform: uppercase;
  width: 100%;

  strong {
    font-weight: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(43)}rem;
    line-height: ${toVWDesktop(40)}vw;
    margin-bottom: ${toVWDesktop(14)}vw;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  gap: ${toVWMobile(16)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    grid-template-columns: 1fr 1fr;
    gap: ${toVWDesktop(19)}vw;
  }
`;

export default ThisRecipeFeatures;
