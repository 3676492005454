import React, { useRef } from "react";
import { Image as ImageType } from "../../types";
import styled from "styled-components";
import Logo from "../../assets/images/favicon.png";
import { BREAKPOINTS } from "../../helpers/theme";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";

export type PreviewPrintProps = {
  title: string;
  image: ImageType;
  author: string;
  instructions: InstructionType[];
  ingredientsTitle: string;
  ingredientsContent: string;
  preparationTitle: string;
  preparationContent: string;
};

type InstructionType = {
  text: string;
  quantity: string;
};

const PreviewPrint = ({
  title,
  image,
  author,
  instructions,
  ingredientsTitle,
  ingredientsContent,
  preparationTitle,
  preparationContent,
}: PreviewPrintProps): JSX.Element => {
  const imageRef = useRef<any>();

  return (
    <Recipe>
      <LogoContainer>
        <LogoImage crossOrigin="anonymous" src={Logo} />
        <LogoText>BEYOND MEAT</LogoText>
      </LogoContainer>
      <br />
      <HeadContainer>
        <Title>{title}</Title>
        <Author>{author}</Author>
        <br />
        <MainImage
          ref={imageRef}
          crossOrigin="anonymous"
          src={`${process.env.GATSBY_IMAGE_PROXY_URL}/?imageSrc=${image.src}`}
          alt={image.alt}
        />
      </HeadContainer>
      <br />
      <TextContainer>
        <Instructions>
          {instructions &&
            instructions.map((inst) => (
              <div key={inst.text}>
                <InstructionsText>
                  <strong>{inst?.text}:</strong> {inst?.quantity}
                </InstructionsText>
              </div>
            ))}
        </Instructions>
        <br />
        <br />
        <Subtitle>{ingredientsTitle}</Subtitle>
        <br />
        <Content
          dangerouslySetInnerHTML={{
            __html: ingredientsContent,
          }}
        />
        <br />
        <br />
        <Subtitle>{preparationTitle}</Subtitle>
        <br />
        <Content
          dangerouslySetInnerHTML={{
            __html: preparationContent?.preparationList,
          }}
        />
      </TextContainer>
    </Recipe>
  );
};

const Recipe = styled.div`
  width: 100%;
`;

const HeadContainer = styled.div`
  width: ${toVWDesktop(330)}vw;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  gap: 10px;
`;

const LogoImage = styled.img`
  width: 25px;
  height: 25px;
`;

const LogoText = styled.p`
  font-size: ${toREM(10)}rem;
  font-weight: 900;
  margin: 0;
`;

const Title = styled.h2`
  font-size: ${toREM(15)}rem;
  margin: 0;
`;

const Author = styled.p`
  font-size: ${toREM(8)}rem;
  text-transform: capitalize;
  margin: 0;
`;

const MainImage = styled.img`
  width: 250px;
  height: 250px;
  object-fit: contain;
`;

const TextContainer = styled.div`
  color: #000000;
  height: fit-content;
  font-size: ${toREM(8)}rem;
  width: ${toVWMobile(150)}vw;
  max-width: ${toVWMobile(150)}vw;

  .ingredients-quantity {
    color: #000000 !important;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(11)}rem;
    width: ${toVWDesktop(330)}vw;
    max-width: ${toVWDesktop(330)}vw;
  }
`;

const Instructions = styled.div`
  width: ${toVWDesktop(330)}vw;
`;

const InstructionsText = styled.p`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  gap: 3px;
`;

const Subtitle = styled.h3`
  font-size: ${toREM(10)}rem;
  margin: 0;
`;

const Content = styled.p`
  display: flex;
  flex-direction: column;
  width: ${toVWDesktop(330)}vw;
  font-size: ${toREM(10)}rem;
  gap: 5px;
  margin: 0;

  p {
    display: flex;
    flex-direction: row;
  }

  span {
    margin-right: 4px;
  }
`;

export default PreviewPrint;
