import React, { FC, useMemo, useRef } from "react";
import styled from "styled-components";
import RecipeIngredients from "../../components/RecipeIngredients";
import RecipePreparation, {
  RecipePreparationProps,
} from "../../components/RecipePreparation";
import { ThisRecipeFeaturesProps } from "../../components/ThisRecipeFeatures";
import {
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import Tabs from "../Tabs";
import { useRecipesMainTab } from "../../hooks/useRecipesMainTab";

export type RecipeDetailsProps = {
  isPublished?: boolean;
  leftTabLabel: string;
  rightTabLabel: string;
  leftTabContent: string;
  rightTabContent: RecipePreparationProps;
  card?: ThisRecipeFeaturesProps;
};

const RecipeDetails: FC<RecipeDetailsProps> = ({
  isPublished,
  leftTabLabel,
  rightTabLabel,
  leftTabContent,
  rightTabContent,
  card,
}: RecipeDetailsProps) => {
  const containerRef = useRef<HTMLElement>(null);

  if (!isPublished) return null;

  const tabs = useMemo(() => {
    const mainTab = useRecipesMainTab();
    const tTabs = [];
    if (leftTabContent) {
      tTabs.push({
        tag: "H2",
        title: leftTabLabel,
        children: (
          <RecipeIngredients
            containerRef={containerRef}
            leftTabContent={leftTabContent}
            card={card}
          />
        ),
      });
    }
    if (rightTabContent && rightTabContent?.preparationList) {
      tTabs.push({
        tag: "H2",
        title: rightTabLabel,
        children: (
          <RecipePreparation containerRef={containerRef} {...rightTabContent} />
        ),
      });
    }
    if (mainTab === "preparation") {
      const prepTab = tTabs[1];
      tTabs[1] = tTabs[0];
      tTabs[0] = prepTab;
    }
    return tTabs;
  }, [card, leftTabContent, rightTabContent]);

  return (
    <Container ref={containerRef}>
      <Tabs tabs={tabs} />
    </Container>
  );
};

const Container = styled.section`
  background-color: #033305;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(580)}vw ${toVWDesktop(109)}vw ${toVWDesktop(205)}vw;
    position: relative;
    min-height: ${toVWDesktop(762)}vw;
  }

  & .tab-container {
    background-color: #033305;
    padding: ${toVWMobile(44)}vw ${toVWMobile(19)}vw;
    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      gap: ${toVWDesktop(40)}vw;
      padding: ${toVWDesktop(88)}vw 0;
    }
  }

  & .tab {
    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      font-size: ${toREM(48)}rem;
      line-height: ${toVWDesktop(56)}vw;
      height: ${toVWDesktop(69)}vw;
    }
  }
`;

export default RecipeDetails;
