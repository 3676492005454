import { useMediaQuery } from "@react-hook/media-query";
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import styled from "styled-components";
import {
  toPixelsFromVW,
  toREM,
  toVWDesktop,
  toVWMobile,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA } from "../../helpers/theme";
import { useIntl } from "../../hooks/useIntl";

export type RecipePreparationProps = {
  url: string;
  preparationList: string;
  containerRef: MutableRefObject<HTMLElement | null>;
};

const RecipePreparation: FC<RecipePreparationProps> = ({
  url,
  preparationList,
  containerRef,
}: RecipePreparationProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const desktopMatches = useRef(false);
  const mediaMatches = useMediaQuery(MEDIA.DESKTOP);
  const [updatedPreparation, setUpdatedPreparation] = useState<string>("");
  desktopMatches.current = mediaMatches;
  const intl = useIntl();

  const onScroll = useRef(() => {
    const container = containerRef.current;
    const div = videoRef.current;
    const nav = document.getElementById("theNavBar");

    if (!(nav && container && div && desktopMatches.current)) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const containerRect = container.getBoundingClientRect();
    const containerTop = containerRect.top + scrollTop;
    const containerHeight = containerRect.height;
    const divRect = div.getBoundingClientRect();
    const divHeight = divRect.height;
    const PADDING_TOP = toPixelsFromVW(toVWDesktop(94));
    const PADDING_BOTTOM = toPixelsFromVW(toVWDesktop(80));

    const top = window.scrollY + nav.offsetHeight + PADDING_TOP;

    if (top < containerTop + PADDING_TOP) {
      div.style.position = "absolute";
      div.style.top = `${PADDING_TOP}px`;
    }
    if (top >= containerTop + PADDING_TOP) {
      div.style.position = "fixed";
      div.style.top = `${nav.offsetHeight + PADDING_TOP}px`;
    }
    if (top >= containerTop + containerHeight - divHeight - PADDING_BOTTOM) {
      div.style.position = "absolute";
      div.style.top = `${containerHeight - divHeight - PADDING_BOTTOM}px`;
    }
  }).current;

  useEffect(() => {
    if (desktopMatches.current) {
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, []);

  useEffect(() => {
    onScroll();
  }, [videoRef.current]);

  const replacePTagsWithItemProp = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const pTags = doc.querySelectorAll("p");

    pTags.forEach((pTag) => {
      pTag.setAttribute("itemProp", "recipeInstructions");
    });

    setUpdatedPreparation(doc.body.innerHTML);
  };

  useEffect(() => {
    replacePTagsWithItemProp(preparationList);
  }, [preparationList]);

  return (
    <Container newPadding={intl.locale === "he-IL" ? true : false}>
      {url && (
        <VideoContainer ref={videoRef}>
          <StyledReactPlayer
            width={isMobile ? `${toVWMobile(337)}vw` : `${toVWDesktop(450)}vw`}
            height={isMobile ? `${toVWMobile(191)}vw` : `${toVWDesktop(300)}vw`}
            url={url}
          />
        </VideoContainer>
      )}
      <RecipeDescription
        dangerouslySetInnerHTML={{ __html: updatedPreparation }}
      />
    </Container>
  );
};

const Container = styled.div<{ newPadding?: boolean }>`
  background-color: #033305;
  padding: 0 ${toVWMobile(20)}vw ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${(props) =>
      props.newPadding === true
        ? `0 ${toVWDesktop(170)}vw 0 0`
        : `0 ${toVWDesktop(40)}vw 0 0`};
  }
`;

const VideoContainer = styled.div`
  padding-bottom: ${toVWMobile(39)}vw;
  height: ${toVWMobile(250)}vw;
  border-bottom: 1px solid #2b6447;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    right: ${toVWDesktop(100)}vw;
    height: ${toVWDesktop(300)}vw;
  }
`;

const StyledReactPlayer = styled(ReactPlayer)``;

const RecipeDescription = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(28)}vw;
  }

  p {
    margin-bottom: ${toVWMobile(20)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(20)}vw;
    }
  }

  h3,
  b,
  strong {
    border-top: 1px solid #2b6447;
    color: #ffffff;
    font-family: ${(props) => props.theme.fontFamily},
      ${(props) => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${toREM(24)}rem;
    font-weight: 900;
    font-style: normal;
    letter-spacing: ${toREM(1.44)}rem;
    line-height: normal;
    padding: ${toVWMobile(20)}vw 0;
    text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
    text-transform: uppercase;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding: ${toVWDesktop(20)}vw 0;
    }
  }
`;

export default RecipePreparation;
