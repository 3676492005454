import React, { FC, useMemo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Image } from "../../types";
import SVG from "react-inlinesvg";
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import Link from "../shared/Link";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import LeftArrow from "../../assets/images/carousel_left_arrow.svg";
import RightArrow from "../../assets/images/carousel_right_arrow.svg";
import DownloadIcon from "../../assets/images/downloadIcon.svg";
import FbIcon from "../../assets/images/facebookIcon.svg";
import XIcon from "../../assets/images/xIcon.svg";
import ThreadsIcon from "../../assets/images/threadsIcon.svg";
import EmailIcon from "../../assets/images/emailIcon.svg";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { transformISOtoText } from "../../helpers/isoDuration";
import useRightToLeft from "../../hooks/useRightToLeft";
import printIcon from "../../assets/images/printIcon.svg";
import loadingIcon from "../../assets/images/loadingIcon.gif";

export type RecipeDetailMainProps = {
  isPublished?: boolean;
  image: Image;
  title: string;
  schemaTitle?: string;
  author: string;
  instructions: InstructionType[];
  back: { label: string; link: string };
  file?: string;
  printFunction: () => void;
  loading: boolean;
};
type InstructionType = {
  text: string;
  quantity: string;
  itemprop?: string;
  localeValue: string | null | undefined;
};
const RecipeDetailMain: FC<RecipeDetailMainProps> = ({
  isPublished,
  image,
  title,
  schemaTitle,
  author,
  instructions,
  file,
  back,
  printFunction,
  loading,
}: RecipeDetailMainProps) => {
  if (!isPublished) return null;
  const labels = useStaticLabels();
  const isRTL = useRightToLeft();
  const [updatedInstructions, setUpdatedInstructions] = useState<
    InstructionType[]
  >([]);
  const getDescription = () => {
    const [firstInstruction = {}] = instructions;
    const instruction = `${firstInstruction?.text}: ${firstInstruction?.quantity}`;
    return author
      ? `${title}\n${author}\n${instruction}`
      : `${title}\n${instruction}`;
  };
  const url = useMemo(() => {
    if (typeof window !== "undefined") {
      return window?.location?.href;
    }
  }, []);
  const print = () => {
    useEffect(() => {
      printFunction();
    }, [image]);
  };

  const addItemprop = (inst: InstructionType[]) => {
    inst.forEach((obj, index) => {
      switch (index) {
        case 0:
          obj.itemprop = "recipeYield";
          break;
        case 1:
          obj.itemprop = "prepTime";
          obj.localeValue = transformISOtoText(
            obj.quantity,
            labels.minutes,
            labels.hour,
            labels.hours
          );
          break;
        case 2:
          obj.itemprop = "cookTime";
          obj.localeValue = transformISOtoText(
            obj.quantity,
            labels.minutes,
            labels.hour,
            labels.hours
          );
          break;
        default:
          break;
      }
    });
    setUpdatedInstructions(inst);
  };

  const shareButtonThreads = () => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const centerX = (screenWidth - 550) / 2;
    const centerY = (screenHeight - 400) / 2;
    const newPost = "https://www.threads.net/intent/post?text=";
    const recipeUrl = window.location.href;
    const bmUser = "@beyondmeat"

    const popupOptions = "width=550,height=400,location=no,left=" + centerX + ",top=" + centerY;
    window.open(`${newPost}${title} ${recipeUrl} ${bmUser}`, "_blank", popupOptions);
  }

  useEffect(() => {
    addItemprop(instructions);
  }, [instructions]);

  return (
    <Container>
      <TopContainer>
        <StyledLink to={back?.link}>
          <Arrow src={isRTL ? RightArrow : LeftArrow} />
          <BackText>{back?.label}</BackText>
        </StyledLink>
        <Picture itemProp="image" src={image?.src} alt={image?.alt} />
      </TopContainer>
      <BottomContainer>
        <StyledLinkDesktop to={back?.link}>
          <Arrow src={isRTL ? RightArrow : LeftArrow} />
          <BackText>{back?.label}</BackText>
        </StyledLinkDesktop>
        {schemaTitle ? (
          <>
            <SchemaTitle itemProp="name">{schemaTitle}</SchemaTitle>
            <Title>{title}</Title>
          </>
        ) : (
          <Title itemProp="name">{title}</Title>
        )}
        {author && <Description itemProp="author">{author}</Description>}
        <InstructionWrapper>
          {updatedInstructions?.map((instruction, index) => (
            <Instruction key={index}>
              <InstructionText>{instruction?.text}</InstructionText>
              <InstructionQuantity
                itemProp={instruction.itemprop}
                content={instruction?.quantity}
              >
                {instruction?.itemprop === "recipeYield"
                  ? instruction?.quantity
                  : instruction?.localeValue}
              </InstructionQuantity>
            </Instruction>
          ))}
        </InstructionWrapper>
        <SocialIconsWrapper>
          <FacebookShareButton
            quote={getDescription()}
            hashtag={`#BeyondMeat`}
            url={url}
          >
            <SmallBtn src={FbIcon} />
          </FacebookShareButton>
          <ShareButton
            onClick={() => shareButtonThreads()}
          >
            <SmallBtn src={ThreadsIcon} />
          </ShareButton>
          <TwitterShareButton
            title={getDescription()}
            hashtags={["BeyondMeat"]}
            related={["@beyondmeat"]}
            url={url}
          >
            <SmallBtn src={XIcon} />
          </TwitterShareButton>
          <EmailShareButton url={url} subject={title} body={getDescription()}>
            <SmallBtn src={EmailIcon} />
          </EmailShareButton>
          {loading ? (
            <LoadingIcon src={loadingIcon} />
          ) : (
            <SmallBtn onClick={printFunction} src={printIcon} title={"Print"} />
          )}
          {file && (
            <Link to={file} aria-label={labels?.download}>
              <SmallBtn src={DownloadIcon} title={labels?.download} />
            </Link>
          )}
        </SocialIconsWrapper>
      </BottomContainer>
    </Container>
  );
};
const Container = styled.section`
  background-color: #471d0e;
  padding: ${toVWMobile(53)}vw ${toVWMobile(20)}vw ${toVWMobile(40)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: row-reverse;
    min-height: ${toVWDesktop(570)}vw;
    padding: 0 ${toVWDesktop(100)}vw 0 ${toVWDesktop(205)}vw;
  }
`;
const TopContainer = styled.div`
  margin-bottom: ${toVWMobile(52)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: 0;
    z-index: 5;
  }
`;
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: ${toVWMobile(42)}vw;
  text-decoration: none;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;
const StyledLinkDesktop = styled(StyledLink)`
  display: none;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
    display: flex;
    justify-content: "flex-start";
    margin-bottom: ${toVWDesktop(20)}vw;
    position: relative;
    cursor: pointer;
  }
`;
const Arrow = styled(SVG)`
  margin-left: ${toVWMobile(8)}vw;
  width: ${toVWMobile(20)}vw;
  height: ${toVWMobile(11)}vw;
  fill: #dd9271;
  z-index: 1;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: ${toVWDesktop(8)}vw;
    width: ${toVWDesktop(20)}vw;
    height: ${toVWDesktop(11)}vw;
  }
`;
const BackText = styled.span`
  color: #dd9271;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: ${toREM(0.16)}rem;
  line-height: normal;
  margin-left: ${toVWMobile(8)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: ${toVWDesktop(8)}vw;
  }
`;
const Picture = styled.img`
  width: ${toVWMobile(337)}vw;
  height: ${toVWMobile(337)}vw;
  object-fit: contain;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(610)}vw;
    height: ${toVWDesktop(610)}vw;
  }
`;
const BottomContainer = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${(props) =>
      props.theme.isRTL
        ? `${toVWDesktop(46)}vw 0 ${toVWDesktop(140)}vw ${toVWDesktop(100)}vw`
        : `${toVWDesktop(46)}vw ${toVWDesktop(100)}vw ${toVWDesktop(140)}vw 0`};
    text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  }
`;
const ShareButton = styled.button`
  width: ${toVWMobile(30)}vw;
  height: ${toVWMobile(53)}vw;
  background-color: transparent;
  border: 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(26)}vw;
    height: ${toVWDesktop(30)}vw;
  }
`;
const Title = styled.h1`
  color: #ccb983;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(48)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: ${toVWMobile(52)}vw;
  margin-bottom: ${toVWMobile(15)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    letter-spacing: ${toREM(1.68)}rem;
    line-height: ${toVWDesktop(64)}vw;
    margin-bottom: ${toVWDesktop(19)}vw;
  }
`;
const SchemaTitle = styled.p`
  display: none;
`;
const Description = styled.p`
  color: #ccb983;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: normal;
  margin-bottom: ${toVWMobile(46)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(46)}vw;
  }
`;
const InstructionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${toVWMobile(16)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(42)}vw;
  }
`;
const Instruction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${toVWMobile(26)}vw;
  &:not(:last-child) {
    margin-right: ${(props) => (props.theme.isRTL ? 0 : `${toVWMobile(40)}vw`)};
    margin-left: ${(props) => (props.theme.isRTL ? `${toVWMobile(40)}vw` : 0)};
    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-right: ${(props) =>
        props.theme.isRTL ? 0 : `${toVWDesktop(40)}vw`};
      margin-left: ${(props) =>
        props.theme.isRTL ? `${toVWDesktop(40)}vw` : 0};
    }
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(15)}vw;
  }
`;
const InstructionText = styled.p`
  color: #ccb983;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: normal;
  text-transform: uppercase;
`;
const InstructionQuantity = styled.p`
  color: #ccb983;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.08)}rem;
  line-height: ${toVWMobile(40)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(40)}vw;
  }
`;
const SocialIconsWrapper = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: ${(props) => (props.theme.isRTL ? 0 : `${toVWMobile(12)}vw`)};
    margin-left: ${(props) => (props.theme.isRTL ? `${toVWMobile(12)}vw` : 0)};
    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-right: ${(props) =>
        props.theme.isRTL ? 0 : `${toVWDesktop(12)}vw`};
      margin-left: ${(props) =>
        props.theme.isRTL ? `${toVWDesktop(12)}vw` : 0};
    }
  }
`;
const SmallBtn = styled(SVG)`
  width: ${toVWMobile(28)}vw;
  height: ${toVWMobile(28)}vw;
  cursor: pointer;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(28)}vw;
    height: ${toVWDesktop(28)}vw;
  }
`;

const LoadingIcon = styled.img`
  width: ${toVWMobile(28)}vw;
  height: ${toVWMobile(28)}vw;
  cursor: pointer;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(28)}vw;
    height: ${toVWDesktop(28)}vw;
  }
`;
export default RecipeDetailMain;
